.search-box{
    display: flex;
    width: fit-content;
    border: 1px solid #313638;
    color: #777777;
    border-radius: 5px;
    align-items: baseline;
}

.search-box input{
    border: none;
    padding: 10px;
}

.search-box input:focus{
    outline: none;
}

.search-box:focus-within{
    border: 1.2px solid #85B7D9;
}