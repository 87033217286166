.containerbox{
    height: 100vh;
    padding: 15px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.base{
  display: flex;
  height: calc(100vh - 61px);
}

.title-span{
  padding: 0 !important;
  color: #6b6b6b;
  font-size:14px; 
  font-weight:bold;
}

.remove-docs-btn{
  width: fit-content;
  padding: 5px 4px;
  font-size: 12px;
  margin-bottom: 5px
}
.remove-docs-btn:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}

.input-card{
  display: flex;
  flex-direction: column;
  margin: 10px 30px;
}
.input-card input{
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 9px;
}
.input-card input:focus{
  outline: 1px solid skyblue;
}
.input-card label{
  font-size: 10px;
  font-weight: 700;
}

.mini-card{
  display: block;
  justify-content: space-between;
  border-left: 3px solid #99d0e7;
  border-bottom: 1px solid #dedede;
  padding: 10px 10px;
}
/* .mini-card:last-child{border: none;} */
.mini-card:hover{
  border-left: 5px solid #45b4df;
  background-color: #f6f6f6
}

.accordian-head{
  display: flex; 
  justify-content: left;
  margin-bottom: 20px;
}

.accordion-title{
  padding: 1.3em 1em !important;
}

.category-accordion{
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-weight: bold;
  padding: 1em 0.5em !important;
  border-bottom: 1px solid #dfdcdc;
}

.category-accordion:hover{
  background-color: #ececec;
}

.sub-accoridon{
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 0.2em 0.5em !important;
  border-bottom: 1px solid #3c6585;
  margin: 5px 5px;
}
.accordion-content{
  margin-bottom: 15px;
  padding: 0.1em 0em !important;
}

.docs{
  cursor: pointer;
  color: #777;
}

.docs-modal-action{
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-top: 15px;
  border-bottom: 1px solid #c3c3c3;
}


.align-temp{
  margin: 0 !important;
}
.input-card{
  display: flex;
  flex-direction: column;
}
.input-card input{
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 8px;
}
.input-card input:focus{
  outline: 1px solid skyblue;
}
.input-card label{
  font-size: 10px;
  font-weight: 700;
}

.folder-box:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.skeleton {animation: skeleton-loading 0.5s linear infinite alternate;}
.skeleton2 {animation: skeleton-loading2 0.8s linear infinite alternate;}

/* sorting container */

.sorting-spacing{
  margin: 20px 0;
}
.sorting-spacing2{
  margin: 10px 0 0 5px;
}

.sorting-container{
  display: flex;
  width: fit-content;
  cursor: pointer;

}

.sorting-content{
  position: relative;
  z-index: 1;
}

.sorting-dd{
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: max-content;
  border: 1px solid #313638;
}
.sorting-dd-text{
  color: #313638;
  padding: 10px 20px;
}
.sorting-dd-text:hover{
  color: #fff;
  font-weight: 600;
  background-color: #50284d;
  cursor: pointer;
}

/* api card */
.api-card{
  border: 1px solid #cbcbcb;
  background-color: #e3dee28e;
  margin:10px;
  padding: 5px;
  border-radius: 10px;
}
.api-card:hover{
  background-color: #e3dee2d6 /* #4f264b; */
}
.api-title{
  margin: 5px;
  padding-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: bold;
}
.api-stage{
  font-size: 0.9rem;
  color: #fff;
  cursor: pointer;
  background-color: #9b9b9b;
  border-radius: 10px;
  margin: 3px;
  padding: 2px 8px;
}
.active-stage{
  background-color: #50284d;
}


@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 20%);
    }
    100% {
        background-color: hsl(0, 0%, 25%);
    }
}

@keyframes skeleton-loading2 {
    0% {
        background-color: hsl(0, 0%, 80%);
    }
    100% {
        background-color: hsl(0, 0%, 90%);
    }
}
