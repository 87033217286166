*{
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/************************** nav bar **************************/
.nav-bar-container{
    display: flex;
    height: 70px;
    border-bottom: 1px solid #e8e2e2;
}

.nav-bar-icon{
    flex: 10%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
}

.nav-bar-left{
    flex: 30%;
    background-color: #fff;
    /* border: 1px solid green; */
}

.nav-bar-right{
    flex: 60%;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid blue; */
}

.nav-bar-logo img{
    height: 35px;
}
.nav-bar-logo:hover{
    cursor: pointer;
}

.nav{
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
}
.nav li{
    padding: 0 10px;
}
.nav li a {
    display: block;
    color: #313638;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
}

.nav li a:hover{
    color: #318500;
}
/************************** nav bar end **************************/


/************************** home page **************************/
.home-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing-bg{
    width: 100%; 
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: cover; 
}

.our-api-btn{
    color: #e1e6e7;
    padding: 10px 20px;
    font-weight: 600;
    border: 1px solid #e1e6e7;
    width: fit-content;
}
.our-api-btn:hover{
    color: #e1e6e7;
    background-color: #e1e6e755;
}
/************************** home page end **************************/


/************************** getting start **************************/
.gs-container{
    width: 100%;
    overflow: hidden;
}
/************************** getting start end **************************/

@media only screen and (max-width: 780px) {
    .nav li a{
        font-weight: 300;
    }
    .nav li{
        padding: 0px 5px;
    }
}

@media only screen and (max-width: 680px) {
    .nav-bar-icon{
        flex: none;
        justify-content: center;
    }

    .nav-bar-left{
        flex: none;
    }

    .nav-bar-container{
        height: auto;
        flex-direction: column;
    }

    .nav-bar-right{
        flex: none;
        justify-content: center;
    }

    .nav{
        flex-direction: column;
    }

    .nav li a{
        font-weight: 400;
        margin: 2px 0;
    }

    /* home page */
    .home-container{
        padding: 0 0 0 10px;
        align-items: flex-start;
        justify-content: center;
    }

    .landing-bg{height: 300px;}

    .gs-container{overflow-y: scroll;}
}

@media only screen and (max-height: 812px) {
    .landing-page-txt{
        display: none;
    }
}