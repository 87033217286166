body{
    background-color: #f8f8f8;
}

.title-span{
    color: #313638;
    font-size:14px; 
    font-weight:bold;
}

.containerBox{
    height: 80px; 
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px #f1f1f1;
    border-radius: 10px;
    border: 1px solid #bfc8ca;
    background-color: #ffffff;
    transition: all 0.2s;
}

.inner_p_tag{
    color: #313638;
    font-weight: 500;
    font-size: 1rem;
}

.containerBox:hover{
    transform: scale(1.03);
    cursor: pointer;
}

.folder-box:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.sub-header{
    display: flex;
    justify-content: space-between;
    height:55px;
    padding: 10px;
    border-bottom: 1px solid #dadada;
}

.swagger-container{
    width: 100%;
    height: calc(100vh - 116px);
    overflow-y: scroll;
    scrollbar-width: thin;
}

.loader-container{
    background-color: #00000099;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    z-index: 1;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.skeleton {animation: skeleton-loading 0.5s linear infinite alternate;}
.skeleton2 {animation: skeleton-loading2 0.8s linear infinite alternate;}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 80%);
    }
    100% {
        background-color: hsl(0, 0%, 90%);
    }
}

@keyframes skeleton-loading2 {
    0% {
        background-color: hsl(0, 0%, 80%);
    }
    100% {
        background-color: hsl(0, 0%, 90%);
    }
}